<template>
  <div class="w900">
    <h2 class="subBanner"><img src="@/assets/img/icon_gdia.svg" />회원정보수정</h2>
    <div class="mybetwrap">
       <ul class="joinu">
         <li>{{$t('front.common.memId')}}</li>
         <li class="myfix_id">{{userData.memId}}</li>
       </ul>
       <ul class="joinu">
         <li>{{$t('front.common.password')}}</li>
         <li><input type="password" :placeholder="$t('front.signup.passwordPlaceholder')" v-model="model.memPass"></li>
       </ul>
       <ul class="joinu">
         <li>{{$t('front.mypage.passwordOk')}}</li>
         <li><input type="password" :placeholder="$t('front.signup.passwordPlaceholder')" v-model="model.memPassCheck"></li>
       </ul>
       <ul class="joinu">
         <li>{{$t('front.common.cashOutPass')}}</li>
         <li><input type="text" id="cashOutPass"  maxlength="4"  pattern="[0-9]*" v-model="model.cashOutPass" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  inputmode="numeric" :placeholder="$t('front.signup.cashOutPassPlaceholder')" /></li>
       </ul>
       <ul class="joinu">
         <li>{{$t('front.mypage.ExchangepasswordOk')}}</li>
         <li><input  type="text" id="cashOutPassCheck" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  maxlength="4"  pattern="[0-9]*" inputmode="numeric"  :placeholder="$t('front.signup.cashOutPassPlaceholder')" v-model="model.cashOutPassCheck"></li>
       </ul>
    </div>
    <div class="joinubottom">
     <ul class="joinub">
       <li>{{$t('front.common.level')}}</li>
       <li>{{$t('front.common.level')}} LV. {{userData.memLevel}}</li>
     </ul>
     <ul class="joinub">
       <li>{{$t('front.gnb.casino')}}</li>
       <li>{{$t('front.common.level')}} LV. {{userData.casinoLevel}}</li>
     </ul>
     <ul class="joinub">
       <li>{{$t('front.gnb.slot')}}</li>
       <li>{{$t('front.common.level')}} LV. {{userData.slotLevel}}</li>
     </ul>
     <!--ul class="joinu">
       <li>미니게임</li>
       <li>레벨 LV. {{userData.mgLevel}}</li>
     </ul-->
    </div>
    <div class="boardbtn"><a class="grbbtn" @click="onUpdate">{{$t('front.board.edit')}}</a></div>
  </div>
</template>

<script>
import { isValidOnlyNumber, isValidPassword } from '@/libs/utils'
import { memberUpdate } from '@/api/member'

export default {
  name: 'MyPageInfo',
  data () {
    return {
      model: {
        memPass: '',
        cashOutPass: '',
        cashOutPassCheck: '',
        memPassCheck: ''
      }
    }
  },
  methods: {
    isValidate () {
      const data = this.model
      for (const key of Object.keys(data)) {
        const value = data[key]

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.toString().length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }

        if (data.cashOutPass !== data.cashOutPassCheck) {
          this.onAlert('warningart', 'front.member.compareCashPassCheck')
          return false
        }
      }

      return true
    },
    onUpdate () {
      if (this.isValidate()) {
        const param = {
          memId: this.userData.memId,
          memPass: this.model.memPass,
          cashOutPass: this.model.cashOutPass
        }

        memberUpdate(param).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.onCheck('front.member.completeUpdate')
          } else {
            this.onCheck('api.' + result.resultCode)
          }

          this.model = {
            memPass: '',
            cashOutPass: '',
            cashOutPassCheck: ''
          }
        })
      }
    }
  }
}
</script>
<style scoped>
#cashOutPass, #cashOutPassCheck {
  -webkit-text-security: disc;
}
.mybetwrap {display: flex;flex-direction: column;gap: 30px;}
.joinu {display: flex;gap: 10px; flex-direction: column;}
.joinu li:first-child {color: #fff;font-size: 14px;}
.joinu li + li {position: relative;display: flex;align-items: center;font-size: 14px;width: 100%; background: #161616; height: 40px;  border-radius: 2px;color: #cef528;}
.joinu li.myfix_id { width: 30%; padding-left: 15px;}
.joinu input {padding: 0 0 0 15px;height: 40px;border: 0;width: 100%;font-size: 14px;background: #161616;color: #cef528;}
.joinu input::placeholder {color: #677a19;}
.joinu input:focus {border: 1px solid #cef528;box-sizing: border-box;}
.joinubottom { margin: 20px 0 30px; padding: 13px 0;border-radius: 2px;border: solid 1px #262525;box-sizing: border-box;background-color: #262525;display: flex;flex-direction: column;gap: 10px;}
.joinubottom .joinub {width: 230px; margin: 0 auto;display: flex;align-items: center;justify-content: space-between;}
.joinubottom .joinub li {font-size: 14px;color: #8a8a8a;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
